.landingpage-bgimg {

    background: #000;
    background-image: url("../Assets/pageheader/landing.webp");
    background-position: bottom;
    background-size: cover;
}

.landingpage-blackdrop {
    background-color: #000000ab;
    height: 800px;
}

.landing-content {
    text-align: center;
}

.landing-title {
    font-size: 55px;
    font-family: "Merriweather", serif;
    font-weight: 400;
    color: white;
    padding-top: 12rem;
}

.landing-subtitle {
    font-size: 1.5rem;
    font-family: "Teachers", sans-serif;
    color: white;
}

.landing-button {
    background-color: #6A1B1B;
    color: white;
    border: none;
    border-radius: .5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 15rem;
}

@media only screen and (max-width: 992px) {
    .landingpage-bgimg {
        object-fit: cover;
        background-position: bottom;
        background-size: cover;
    }

    .landingpage-blackdrop {
        background-color: #000000ab;
        height: 500px;
    }

    .landing-title {
        font-size: 2rem;
        padding-top: 4rem;
    }

    .landing-subtitle {
        font-size: 1rem;
    }

    .landing-button {
        border-radius: .5rem;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 18rem;
    }
}

.landingpage-whywe {
    background: #F5F1E0;

}

.landingpage-whywe-content {
    margin-left: 6rem;
    margin-right: 6rem;
    padding-bottom: 6rem;
}

.landingpage-col-centering {
    align-content: center !important;
}

.landingpage-whywe-leftimg {
    width: 80%;
    margin-top: 6rem;
    /* margin-bottom: 6rem; */

}

.landingpage-whywe h1 {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 3rem;
    padding-top: 6rem;
    padding-bottom: 2rem;
}

.landingpage-whywe p {
    font-size: 1.4rem;
    text-align: justify;
    font-family: "Teachers", sans-serif;
}

.Whywe-button {
    background-color: #6A1B1B;
    color: white;
    border: none;
    border-radius: .5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 15rem;
}

@media only screen and (max-width: 992px) {
    .landingpage-whywe-content {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-bottom: 2rem;
    }

    .landingpage-whywe-leftimg {
        height: auto;
        width: 100%;
        object-fit: cover;
        margin-top: 2rem;
        border-radius: .5rem;

    }

    .landingpage-whywe h1 {
        text-align: left;
        font-size: 1.6em;
        line-height: 2rem !important;
        padding-top: 2rem;
        padding-bottom: 0rem !important;
    }

    .landingpage-whywe p {
        font-size: .8em;
        margin-bottom: 0;
        line-height: 1.4rem !important;
        padding-bottom: 1rem;
    }

    .Whywe-button {
        width: 100%;
        border-radius: .5rem;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 1rem;
    }
    .landingpage-col-centering {
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 576px) {}


.landingpage-service {
    background: #FAEBD7;
    /* min-height: 100vh; */
}

.landingpage-service h1 {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 5rem;
}

.landingpage-service-content {
    margin-left: 6rem;
    margin-right: 6rem;
    padding-bottom: 6rem;

}

@media only screen and (max-width: 992px) {
    .landingpage-service-content {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-bottom: 2rem;

    }

    .landingpage-service h1 {
        font-size: 2rem;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
}

.landingpage-package {
    background: #F5F1E0;
    /* min-height: 100vh; */
}

.landingpage-package-content {
    margin-left: 6rem;
    margin-right: 6rem;
    padding-bottom: 6rem;

}

.landingpage-package h1 {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

@media only screen and (max-width: 992px) {
    .landingpage-package-content {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-bottom: 2rem;
    }

    .landingpage-package h1 {
        font-size: 2rem;
        padding-top: 2rem;
        padding-bottom: .5rem;
    }
}



.landingpage-customizedroyalty {
    background: #FAEBD7;
}

.landingpage-customizedroyalty-content {
    margin-left: 6rem;
    margin-right: 6rem;
}

.landingpage-customizedroyalty-blackdrop {

    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
    border: solid 10px #D4A373;
    color: white;
    margin-top: 8rem;
    margin-bottom: 8rem;
    background-image: url(../Assets/customizedbg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    border-radius: 1.5rem;

}

.landingpage-customizedroyalty-insidecontent {
    background-color: #000000ab;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
}

.landingpage-customizedroyalty h1 {
    font-family: "Merriweather", serif;
    color: white;
    font-size: 3.5rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}

.landingpage-customizedroyalty-img {
    margin-top: 5rem;
    border-radius: 40px;
    border: solid 5px #D4A373;
}

.landingpage-customizedroyalty p {
    font-size: 1.3rem;
    padding-bottom: 2rem;
    text-align: center;
    font-family: "Teachers", sans-serif;
    color: white;
}
.landingpage-customizedroyalty-button {
    background-color: #6A1B1B;
    color: white;
    border: none;
    border-radius: .5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 15rem;
}

@media only screen and (max-width: 992px) {
    .landingpage-customizedroyalty-blackdrop {

        margin-left: 0rem;
        margin-right: 0rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 100%;
        border-radius: 1rem;

    }

    .landingpage-customizedroyalty-insidecontent {
        padding: 1rem;
        border-radius: .4rem;
    }
    .landingpage-customizedroyalty-content {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .landingpage-customizedroyalty h1 {
        font-size: 1.5rem;
        padding-top: 1.5rem;
        padding-bottom: .4rem;
    }
    .landingpage-customizedroyalty p {
        font-size: .9rem;
        padding-bottom: 0rem;
        text-align: justify;
    }
    .landingpage-customizedroyalty-button {
        width: 100%;
        border-radius: .5rem;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 1rem;
    }
}





.landingpage-article {
    background: #F5F1E0;
    padding-bottom: 5rem;
    padding-top: 3rem;

}

.landingpage-article-content {
    margin-left: 6rem;
    margin-right: 6rem;
    text-align: center;
}
.landingpage-article-container {
    border-radius: 1rem;
    border: solid 2px #D4A373;
    margin-left: 7rem;
    margin-right: 7rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.landingpage-article a {
    text-decoration: none !important;
}

.landingpage-article h2 {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.landingpage-article-container h1 {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}


.landingpage-article-img {
    height: 8rem;
    width: 8rem;
    background-color: #6E7E4B;
    padding: .1rem;
    text-align: center;
    border-radius: .4rem;
    margin-bottom: 2rem;
    border: solid 2px #6E7E4B;


}

.landingpage-article-container:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: solid 2px #a3940c;
}

.landingpage-article-container:hover .landingpage-article-img {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: solid 2px #a3940c;
}

.landingpage-article-container:hover h1 {
    font-size: 2.4rem;
}



.service-card-icon {
    height: 6rem;
    width: 6rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 992px) {

    .landingpage-article {
        padding-bottom: 3rem;
        padding-top: 3rem;

    }

    .landingpage-article-content {
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: center;
    }
    .landingpage-article h2 {
        font-size: 1.7rem;
        text-align: center;
        padding-top: 0rem;
        padding-bottom: 2rem;
    }

    .landingpage-article-container {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-left: 0rem;
        padding-right: 0rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        margin-bottom: 1rem;
    }
    .landingpage-article-container h2 {
        font-size: 2rem;
        padding-top: .5rem;
        padding-bottom: 0rem;
    }

    .landingpage-article-img {
        height: 7rem;
        width: 7rem;
        padding: 1.3rem;
        border-radius: .4rem;
        margin-bottom: 1rem;

    }

}




.landingpage-hookredirect {

    margin-bottom: 6em;
    margin-bottom: 0rem !important;
    background-color: #000;
    background-image: url("../Assets/workspace.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    /* border-radius: 2rem; */
    font-family: 'Alata', sans-serif;

}

.landingpage-hookredirect-content {
    background-color: #000000d0;
    padding: 6em;
    align-items: center;
    width: 100%;
    --bs-gutter-x: 0rem !important;
}

.landingpage-hookredirect-content h1 {
    font-size: 4rem;
    /* padding-top: 1rem; */
    padding-bottom: 2rem;
    font-family: "Merriweather", serif;
    font-weight: 400;
    color: white;
    text-align: center;
}

.landingpage-hookredirect-buttoncol {
    text-align: center;
}

.landingpage-hookredirect-buttoncol button {
    background-color: #6A1B1B;
    color: white;
    border: none;
    border-radius: .5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 15rem;
    text-align: center;

}


@media only screen and (max-width: 992px) {

    .landingpage-hookredirect-content {
        background-color: #000000d0;
        padding: 2rem;
        align-items: center;
        width: 100%;
        --bs-gutter-x: 0rem !important;
    }

    .landingpage-hookredirect-content p {
        font-size: 1.6rem;
    }

    .landingpage-hookredirect-buttoncol button {
        border-radius: .5rem;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        font-size: 1rem;
    }
    .landingpage-hookredirect-content h1 {
        font-size: 2rem;
    
    }
}

.landingpage-getintouch {
    background-color: #F5F1E0;
    min-height: 100vh;
}




.landingpage-blog {
    background: #FAEBD7;
    /* min-height: 100vh; */
}

.landingpage-blog h1 {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 3rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 5rem;
}

.landingpage-blog-content {
    margin-left: 6rem;
    margin-right: 6rem;
    padding-bottom: 6rem;

}


@media only screen and (max-width: 992px) {


.landingpage-blog {
    padding-bottom: 3rem;
    padding-top: 3rem;

}

.landingpage-blog-content {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    padding-bottom: 0rem;

}

.landingpage-blog h1 {
    font-size: 1.7rem;
    text-align: center;
    padding-top: 0rem;
    padding-bottom: 2rem;
}


}