.Getintouch {
    margin-left: 6rem;
    margin-right: 6rem;
    /* padding-top: 6rem; */
    padding-bottom: 5rem;

}

.Getintouch h2 {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 2.7rem;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.Getintouch-left-content {

    margin-top: 5em;
    justify-content: flex-end;
    font-family: 'Alata', sans-serif;
    text-align: left;

}

.socialmedia-img {
    height: 9rem;
    width: 13rem;
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    position: relative;
    z-index: 10;
}
.socialmedia-img:hover {
    transform: scale(1.05);
}
.Contactusform {
    /* border: solid 0.1px rgba(102, 51, 153, 0.404); */
    /* border-radius: 2rem; */
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
    /* margin-top: 2rem; */
}


.landingpage-contact-label{
    font-size: 1.2rem !important;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: .2rem;
    margin-left: auto;
}
.landingpage-contact-label:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}
.landingpage-contact-label-2{
    font-size: 1.2rem !important;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-left: auto;
}
.contactus {
    color: white;
    text-decoration: none;
    background-color: #6A1B1B;
    border-color: #6A1B1B;
    text-align: center;
    font-size: 1rem;
    border-radius: .5rem;
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
}

.contactus:hover {
    background-color: #a32525;
    border-color: #a32525;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

}

.contactus:active {
    background-color: #a32525;
    border-color: #a32525;
}

.contactform-title {
    font-weight: 700;
    margin-bottom: 2rem;
    font-size: 4em;
}

.Faqcontent {
    margin-top: 4rem;
}

.faq-description {
    font-size: 1rem;
    font-family: "Teachers", sans-serif;
    font-weight: 400;


}

.Getintouch a {
    text-decoration: none !important;
}

@media only screen and (max-width: 992px) {
.landingpage-contact-label-2{
    font-size: 1rem !important;

}
    .landingpage-contact-label{
        font-size: .9rem !important;
        padding-top: .5rem;
        padding-bottom: .5rem;
        border-radius: .2rem;
        margin-left: auto;
    }
    .Getintouch {
        margin-left: .1rem;
        margin-right: .1rem;
        padding-bottom: .5rem;

    }

    .Getintouch-row {
        margin-left: .1rem;
        margin-right: .1rem;
        padding-bottom: 3rem;

    }

    .Getintouch h2 {
        font-size: 2rem;
        text-align: center;
        padding-top: 0rem;
        padding-bottom: .5rem;
    }

    .Getintouch-left-content {
        margin-top: 2rem;
    }

    .socialmedia-img {
        height: 5rem;
        width: 6.9rem;
    }
    .Contactusform {
        border-radius: .5rem;
        margin-top: 1rem;
        margin-left: 0rem;
        margin-right: 0rem;
        padding: 1.5rem;

    }
    .form-label {
        font-size: .9rem
    }

    .contactus {
        width: 100%;
        height: 2.5rem;
        font-size: .9em;
    }

    .contactform-title {
        text-align: center;
        font-size: 2em;
    }

    .Faqcontent {
        margin-top: 2rem;
    }

    .faq-description {
        font-size: .8rem;
    }

    .accordion-button {
        font-size: .8rem !important;
    }
}