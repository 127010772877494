.package-form button.selected {
    background: #556972;
    color: white;
}

.package-form {
    text-align: center;
    margin-bottom: 2.5rem;
}

.package-form button {
    text-align: left;
    align-self: baseline;
    font-size: 1.5rem;
    color: #7A8E97;
    border: 1px solid #7A8E97;
    padding-left: 3em;
    padding-right: 3em;
    background: none;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-radius: .4rem;
    margin: 1rem;
}
.package-form button:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

}


@media only screen and (max-width: 992px) {
    .package-form button {
        font-size: 1rem;
        padding-left: 2em;
        padding-right: 2em;
        justify-content: space-around;
        padding-top: .4rem;
        padding-bottom: .4rem;
        border-radius: .5rem;

    }

    .package-form {
        text-align: center;
        margin-bottom: 1rem;

    }
}