.blog{
    height: 10rem;
}
.BlogSection a{
    text-decoration: none;
    color: #000;
}
.blog-cardcontent-col {
    align-content: center;
    padding: .5rem;
}



.blog-content-card {
    background-color: none;
    margin-left: 4.5rem;
    margin-right: 4.5rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-decoration: none;
}
.blog-swipercard:hover{
    box-shadow: 10px 11px 29px -5px rgba(0,0,0,0.27);
    -webkit-box-shadow: 10px 11px 29px -5px rgba(0,0,0,0.27);
    -moz-box-shadow: 10px 11px 29px -5px rgba(0,0,0,0.27);
}
.blog-cover-img2 {
    height: 13.5rem;
    width: 100%;
    /* padding: .5rem; */
    text-align: center;
    border-radius: .3rem;
    object-fit: cover;
    
}
.blog-swipercard {
    background-color: rgba(255, 255, 255, 0.801);
    text-align: center;
    border: 1px solid #D4A373;
    border-radius: .3rem;
    margin-top: 0em;
    width: fit-content;
}



.blog-cardcontent-title {
    font-size: 1.5rem;
    text-align: center;
    font-family: "Merriweather", serif;
    margin-bottom: 0;
    margin-left: 1rem;
}
.blog-cardcontent-description {
    font-size: 1rem;
    text-align: justify;
    font-family: "Teachers", sans-serif;
    margin-bottom: .3;
    margin-top: .3;
    margin-left: 1rem;
    margin-right: 1.5rem;
    
}
.blog-cardcontent-authorname {
    font-size: 1.2rem;
    text-align: left;
    font-family: "Merriweather", serif;
    font-weight: 600;
    margin-bottom: .2;
    margin-left: 1rem;
    margin-top: 1rem;
    float: left;
}
.blog-cardcontent-posteddate {
    font-size: .9rem;
    text-align: left;
    font-family: "Teachers", sans-serif;
    font-weight: 500;
    margin-bottom: .2;
    margin-left: 1rem;
    float: right;
    margin-right: 1.5rem;
    margin-top: 1rem;


}







@media only screen and (max-width: 992px) {
    .blog-content-card {
        /* background-color: none; */
        margin-left: 0rem;
        margin-right: 0rem;
        margin-bottom: 1rem;
        border-radius: 1rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        text-decoration: none;
    }
}




